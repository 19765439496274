import { render, staticRenderFns } from "./PortalAddress.vue?vue&type=template&id=43cf0060&scoped=true&"
import script from "./PortalAddress.vue?vue&type=script&lang=ts&"
export * from "./PortalAddress.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43cf0060",
  null
  
)

export default component.exports